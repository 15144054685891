<template>
  <div>
    <api-popup
      v-if="checkApiPopup"
      :apiSet="apiSet"
      @call-parents-popup="doLayerPop()"
      @is-login="loginOn()"
      @reload-api-pop="reloadApiPop"
      @require-change-password-done="changePwDone"
    />
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @reload-pop="reloadPop"
    />
  </div>
</template>
<script>
import ApiPopup from "@/components/modal/ApiPopup";
import DefaultPopup from "@/components/modal/DefaultPopupHeader";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  props: {
    headerOption: {
      navOrder: {
        type: Number,
        default: 1,
      },
      isMain: {
        type: Boolean,
        default: false,
      },
      login: {
        id: "",
        pw: "",
      },
    },
  },
  components: {
    ApiPopup,
    DefaultPopup,
  },
  //   data: () => ({
  //       isLogin : false,
  //       username : "응가맨",
  //       login: {
  //           id: "",
  //           pw: "",
  //       }
  //   }),
  data() {
    return {
      navOrder: this.headerOption.navOrder,
      isMain: this.headerOption.isMain,
      lastScrollPosition: 0,
      apiSet: {},
      popupSet: {},
      noteCount: 0,
      referCount: 0,
      isLogin: false,
      username: null,
    };
  },
  mounted() {
    this.checkIsLogin();
  },
  updated() {
    //this.checkIsLogin();
  },
  computed: {
    ...mapState("basic", ["checkApiPopup", "isChildLoading"]),
    ...mapState("basic", {
      checkPopup: (state) => state.header.checkPopup,
    }),
    ...mapGetters("basic", ["getPopConfig"]),
    ...mapGetters("auth", ["GET_IS_LOGIN_ACTIVE"]),
  },
  created() {
    //console.log("isMain", this.isMain);
    //console.log("headerOption : ", this.headerOption);
  },
  methods: {
    ...mapActions("auth", ["LOGIN"]),
    ...mapActions("note", ["NOTE_COUNT", "REFER_COUNT"]),
    ...mapMutations("basic", ["SET_API_POPUP", "SET_POPUP_HEADER"]),
    ...mapMutations("util", ["SET_ISINIT_LOGIN"]),
    ...mapActions("auth", ["IS_LOGIN_ACTIVE"]),
    async isLoginActive() {
      try {
        await this.IS_LOGIN_ACTIVE();
      } catch (error) {
        console.log(error);
        if (sessionStorage.getItem("destinedURL") === "/") {
          this.isLogin = false;
          localStorage.removeItem("token");
        } else {
          this.$router.push("/v/login").catch(()=>{});
        }
      }
    },
    goURL(url) {
      this.$router.push(url).catch(()=>{});
    },
    goRegiste() {
      this.$router.push("/v/join/agreement").catch(()=>{});
    },
    async getNoteCount() {
      try {
        await this.NOTE_COUNT().then((data) => {
          //console.log(data);
          this.noteCount = Number(data.data);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async getReferCount() {
      try {
        await this.REFER_COUNT().then((data) => {
          //console.log(data);
          this.referCount = Number(data.data);
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    goMsg() {
      this.$router.push("/v/mypage/inboxnoteList").catch(()=>{});
    },
    callMessage(data) {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "Message";
      this.apiSet.apiBtnText = "전송";
      this.apiSet.dataParam = data;
    },
    callAuthPop() {
      this.clearLocalStorage();
      this.popupSet.title = "권한확인";
      this.popupSet.content = "권한이 없거나 만료되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.destination = true;
      this.popupSet.nextLink = "/v/login";
      this.popupSet.isTopPriority = true;
      this.popupSet.authCause = true;
      this.SET_POPUP_HEADER(true);
    },
    callNormalPop() {
      this.SET_POPUP_HEADER(true);
      this.popupSet.title = "로그인 확인";
      this.popupSet.content = "로그인이 필요한 메뉴입니다.";
      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
    },
    async login() {
      if (this.login.id !== "" && this.login.pw !== "") {
        const formData = new FormData();
        formData.append("username", this.login.id);
        formData.append("password", this.login.pw);
        try {
          await this.LOGIN(formData).then((data) => {
            //console.log("Login-data: ", data);
            localStorage.setItem("drId", data.drId);
            localStorage.setItem("nm", data.nm);
            localStorage.setItem("token", data.token);
            localStorage.setItem("memType", data.memType);
            localStorage.setItem("pwActiveYn", data.pwActiveYn);
            localStorage.setItem("pwElapYn", data.pwElapYn);
            localStorage.setItem("suspandYn", data.suspandYn);
            localStorage.setItem("popYn", data.popYn);
            if (
              data.suspandYn === "Y" ||
              data.pwActiveYn === "N" ||
              data.pwElapYn === "Y"
            ) {
              if (data.suspandYn === "Y") {
                //console.log("휴면계정처리가 필요함");
                this.$router.push("/v/Recovery").catch(()=>{});
              } else if (data.pwActiveYn === "N") {
                //console.log("비밀번호 오류횟수 초과로 강제변경");
                this.$router.push("/v/requireChangePw").catch(()=>{});
              } else if (data.pwElapYn === "Y") {
                this.$router.push("/v/restrictPw").catch(()=>{});
                //console.log("비밀번호를 사용한지 3개월이 지남");
              }
            } else {
              /** 팝업 붙이고 isLogin 변경 */
              /*this.popupSet.title = "로그인";
              this.popupSet.content = "로그인이 완료되었습니다.";
              this.popupSet.popType = "custom";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.cancelBtnText = undefined;
              this.popupSet.destination = true;
              this.popupSet.nextLink = "/";
              this.popupSet.isTopPriority = true;
              this.SET_POPUP_HEADER(true);*/
              this.popupSet.title = "로그인";
              this.popupSet.content = "로그인이 완료되었습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
              this.SET_POPUP_HEADER(true);
            }
            this.getNoteCount();
            this.getReferCount();
            this.loginOn();
          });
        } catch (error) {
          console.log("error 로그인:", error);
          this.popupSet.title = "로그인 입력정보 확인";
          this.popupSet.content = "아이디 또는 비밀번호가 잘못되었습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          this.SET_POPUP_HEADER(true);
          this.login.id = "";
          this.login.pw = "";
        }
      } else {
        /** 빠질 일 없고 테스트임 */
        this.popupSet.title = "로그인 입력정보 확인";
        this.popupSet.content = "아이디 또는 비밀번호가 잘못되었습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        this.SET_POPUP_HEADER(true);
      }
    },
    enteredLogin() {
      if (this.login.id !== "" && this.login.pw !== "") {
        this.login();
      }
    },
    changePwDone() {
      this.$emit("change-pw-done");
    },
    calledNav(type) {
      //console.log("calledNav :", type);
      if (type === "login") {
        this.callLoginPop();
      } else if (type === "logout") {
        this.callLogOutPop();
      } else if (type === "withDraw") {
        //console.log("헤더 탈퇴 진입");
        this.callwithDrawPop();
      }
    },
    loginOn() {
      this.isLogin = true;
      this.login.id = "";
      this.login.pw = "";
      this.username = localStorage.getItem("nm");
      this.$emit("is-login");
      if (this.navOrder === 2) {
        setTimeout(() => {
          this.getMargin();
        }, 800);
      }
    },
    getMargin() {
      const clientWidth = document.querySelector(".user-status").clientWidth;
      //console.log("user-status width: ", clientWidth);
      const getMarin =
        document.querySelector(".n-title").childNodes[0].offsetLeft -
        clientWidth;
      //console.log("마진찾기: ", getMarin);
      document.querySelector(".user-status").style.right = getMarin + "px";
      document.querySelector(".user-status").classList.add("active");
    },
    checkIsLogin() {
      if (localStorage.getItem("token") !== null) {
        this.isLogin = true;
        this.username = localStorage.getItem("nm");
        this.getNoteCount();
        this.getReferCount();
        this.isLoginActive();
        //console.log("접속");
        if (
          localStorage.getItem("drId") === "admin" ||
          localStorage.getItem("drId") === "DrRefer"
        ) {
          //console.log(localStorage.getItem("drId") + "관리자 확인 중");
          //console.log(this.$route);
          if (this.$route.name === "RestrictPw") {
            this.$router.push("/v/restrictPw").catch(()=>{});
          } else if (this.$route.name === "RequireChangePw") {
            this.$router.push("/v/requireChangePw").catch(()=>{});
          } else {
            this.$router.push("/v/adminMain/0").catch(()=>{});
          }
        } else if (localStorage.getItem("drId") === "drdesign") {
          console.log(localStorage.getItem("drId") + "디자이너 확인");
          console.log(this.$route);
          if (this.$route.name === "RestrictPw") {
            this.$router.push("/v/restrictPw").catch(()=>{});
          } else if (this.$route.name === "RequireChangePw") {
            this.$router.push("/v/requireChangePw").catch(()=>{});
          } else if (this.$route.path !== "/v/designer/main/2") {
            this.$router.push("/v/designer/main/0").catch(()=>{});
          }
        }
      } else {
        //this.callLoginPop();
        this.isLogin = false;
      }
    },
    reloadPop(type) {
      //console.log("popInfo", type);
      setTimeout(() => {
        if (type === "Login") {
          this.callLoginPop();
        }
      }, 800);
    },
    checkIsCheckedIdentity() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "DrLicense";
      this.apiSet.title = "의사면허번호 확인";
      this.apiSet.content =
        "이 서비스는 의사 회원만을 위한 서비스입니다.\n중복 가입을 피하기 위해 의사면호번호를 확인합니다.";
      this.apiSet.placeholder = "의사면허번호 입력";
      this.apiSet.warnMessage =
        "신규 가입한 회원의 의사면허번호는 가입 후 검증합니다.\n" +
        "의사면허번호 및 개인정보가 일치하지 않으면\n" +
        "서비스 사용이 중단됩니다.";
      this.apiSet.apiBtnText = "중복확인";
      this.apiSet.confirmBtnText = "회원가입";
      this.apiSet.nextLink = "/v/join/member";
    },
    callReplyComment(referId, replyComment, str, rcvAttachment) {
      //console.log(referId, replyComment, str, rcvAttachment);
      this.SET_API_POPUP(true);
      this.apiSet.popType = "replyComment2";
      this.apiSet.title = "회신등록";
      this.apiSet.customParam2 = replyComment;
      this.apiSet.customParam = referId;
      this.apiSet.rcvAttachment = rcvAttachment;
    },
    reloadApiPop(popInfo) {
      //console.log("popInfo", popInfo);
      if (popInfo.immediatly !== undefined && popInfo.immediatly === true) {
        this.SET_API_POPUP(true);
      } else {
        setTimeout(() => {
          this.SET_API_POPUP(true);
        }, 800);
      }
      this.apiSet.popType = popInfo.popType;
      this.apiSet.title = popInfo.title;
      this.apiSet.content = popInfo.content;
      if (popInfo.functionValue !== undefined) {
        //console.log("전송할 데이터파람", popInfo.functionValue);
        this.apiSet.dataParam = popInfo.functionValue;
      }
    },
    callFindPwCheckPop() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "FindPw";
      this.apiSet.title = "비밀번호 찾기";
      this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
    },
    callPwCheckPop() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "CheckPassword";
      this.apiSet.title = "비밀번호 확인";
      this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
    },
    callPwOwnCheckPopup() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "CheckPasswordOwn";
      this.apiSet.title = "비밀번호 확인";
      this.apiSet.content = "비밀번호를 확인한 후 변경할 수 있습니다.";
    },
    callLoginPop(defineUrl, type) {
      //console.log(this.$route.name);
      if (this.$route.name !== "Home") {
        //console.log("헤더에서부른 로그인팝 예약URL", defineUrl);
        /**
        const d = document.location.href.replace("localhost:8080", "");
         */
        const d = document.location.href.replace("https://www.drrefer.net", "");
        //console.log("d의값", d);
        /*
        console.log(
          "검증",
          d === sessionStorage.getItem("pathToLoadUnAuthInApi")
        );
        */

        this.SET_API_POPUP(true);
        this.apiSet.popType = "LoginMain";
        this.apiSet.title = "LOGIN";
        this.apiSet.content =
          "의사 회원만 이용 가능한 서비스 입니다.\n로그인 후 검색 하실 수 있습니다.";
        if (defineUrl !== undefined) {
          this.apiSet.defineUrl = defineUrl;
          //console.log("찾기", sessionStorage.getItem("destinedURL"));
          if (sessionStorage.getItem("destinedURL") !== null) {
            this.apiSet.defineUrl = sessionStorage.getItem("destinedURL");
          }
        }
        if (d === sessionStorage.getItem("pathToLoadUnAuthInApi")) {
          this.apiSet.defineUrl = "reload";
        }
        if (type !== undefined && type === "fixing") {
          this.apiSet.isFixing = true;
        }
      }
    },
    callFindIdPop() {
      this.SET_API_POPUP(true);
      this.apiSet.popType = "FindId";
      this.apiSet.title = "아이디 찾기";
      this.apiSet.content = "휴대폰으로 전송된 인증번호를 입력해주세요.";
    },
    doLayerPop() {
      //console.log("가져온 레이어팝업 재료", this.getPopConfig);
      this.popupSet.title = this.getPopConfig.title;
      this.popupSet.content = this.getPopConfig.content;
      /** 팝업 타입 */
      if (this.getPopConfig.popType !== undefined) {
        this.popupSet.popType = this.getPopConfig.popType;
      } else {
        this.popupSet.popType = "warn";
      }
      if (this.getPopConfig.confirmBtnText !== undefined) {
        this.popupSet.confirmBtnText = this.getPopConfig.confirmBtnText;
        this.popupSet.cancelBtnText = this.getPopConfig.cancelBtnText;
      }
      this.popupSet.cancelBtnText = this.getPopConfig.cancelBtnText;
      if (this.getPopConfig.action !== undefined) {
        this.popupSet.action = this.getPopConfig.action;
      }
      if (this.getPopConfig.defineUrl !== undefined) {
        this.popupSet.nextLink = this.getPopConfig.defineUrl;
        this.popupSet.destination = true;
      }
      if (this.getPopConfig.authCause !== undefined) {
        this.popupSet.authCause = this.getPopConfig.authCause;
      }
      this.popupSet.isTopPriority = true;
      this.SET_POPUP_HEADER(true);
      //console.log("최종 팝업셋값", this.popupSet);
    },

    clearLocalStorage() {
      this.isLogin = false;
      localStorage.removeItem("drId");
      localStorage.removeItem("nm");
      localStorage.removeItem("token");
      localStorage.removeItem("memType");
      localStorage.removeItem("pwActiveYn");
      localStorage.removeItem("pwElapYn");
      localStorage.removeItem("suspandYn");
      localStorage.removeItem("popYn");
    },
    callwithDrawPop() {
      //console.log("엡 헤더 탈퇴 팝업 진입");
      this.clearLocalStorage();
      this.popupSet.title = "탈퇴완료";
      this.popupSet.content = "정상적으로 회원탈퇴 되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      this.popupSet.destination = true;
      this.popupSet.nextLink = "/";
      this.popupSet.isTopPriority = true;
      this.SET_POPUP_HEADER(true);
    },
    initLoginStatus() {
      //console.log("로그인 초기롸로직 실행됨");
      this.isLogin = false;
    },
    initLogin() {
      this.SET_ISINIT_LOGIN(true);
    },
    callLogOutPop() {
      this.clearLocalStorage();
      this.popupSet.title = "로그아웃";
      this.popupSet.content = "정상적으로 로그아웃되었습니다.";
      this.popupSet.popType = "custom";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.cancelBtnText = undefined;
      const locate = document.location.href;
      const d = locate.replace("https://www.drrefer.net", "");
      //console.log("Destination", d);
      if (d !== "/") {
        //console.log("destination 진입");
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/";
      }
      this.popupSet.isTopPriority = true;
      this.isLogin = false;
      this.SET_POPUP_HEADER(true);
    },
    testScroll(cb) {
      var rAfTimeout = null;

      return function () {
        if (rAfTimeout) {
          window.cancelAnimationFrame(rAfTimeout);
        }
        rAfTimeout = window.requestAnimationFrame(function () {
          cb();
        });
      };
    },
  },
  beforeUnmount() {
    if (this.checkPopup) {
      this.SET_POPUP_HEADER(false);
    }
    if (this.checkApiPopup) {
      this.SET_API_POPUP(false);
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: "viewport",
          name: "viewport",
          content:
            "width=device-width, height=device-height, initial-scale=0.25, minimum-scale=0.25, maximum-scale=1.0, user-scalable=yes",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.v-btn--is-elevated {
  box-shadow: none;
  border-radius: 50px;
}
.app-header-wrap {
  padding-bottom: 15px;
  height: auto; /////////////////////////
  position: relative;
  background: #fff;
  border-bottom: 1px solid #e1e1e1;
  &.active {
    height: auto;
    padding-top: 15px;
  }
}
@media screen and (max-width: 1575px) {
  .header-icons {
    text-align: left !important;
  }
  .header-buttons-btn-first {
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 1073px) {
  .header-top {
    text-align: center !important;
    padding-top: 20px;
  }

  .not-login {
    padding: 5px 12px 0px 0px;
  }
}
@media screen and (max-width: 960px) {
  .header-icons {
    text-align: center !important;
  }
  .header-buttons {
    text-align: center !important;
  }
  .not-login {
    padding: 20px 0 15px 0;
    margin-top: 15px;
  }
  .non-login-text {
    padding: 0px;
    margin: -5px 0 -12px 0;
  }
  .not-login-text-field {
    width: 50%;
    transform: translate(50%, 50%);
  }
}
@media screen and (max-width: 600px) {
  .header-buttons {
    min-width: 300px !important;
    display: inline-block;
    .header-buttons-btn {
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 420px) {
  .header-hellow {
    font-size: 13px !important;
  }
}
</style>
