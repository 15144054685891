<template>
  <div class="app-context util-page" style="height: 900px;">
    <login-component
      @is-login="loginOn()"
      :headerOption="headerOption"
      ref="appheader"
    />
    <!-- <div
      class="context"
      style="
        width: 100%;
        position: absolute;
        background-color: #fff;
        height: 300px;
        top: 0px;
      "
    ></div> -->
  </div>
</template>

<script>
import LoginComponent from "../../components/login/LoginComponent.vue";
export default {
  components: {
    LoginComponent,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
    };
  },
  created() {},
  mounted() {
    //document.querySelector(".app-header-wrap .auth-action").style.opacity = "0";
    //document.querySelector(".app-header-wrap .app-header").style.opacity = "0";
    this.$nextTick((_) => {
      if (sessionStorage.getItem("destinedURL") !== null) {
        this.$refs.appheader.callLoginPop(
          sessionStorage.getItem("destinedURL"),
          "fixing"
        );
      }
      if (sessionStorage.getItem("pathToLoadUnAuthInApi") !== null) {
        this.$refs.appheader.callLoginPop(
          sessionStorage.getItem("pathToLoadUnAuthInApi"),
          "fixing"
        );
      }
    });
  },
  computed: {},
  methods: {
    loginOn() {
      this.$emit("is-login");
    },
  },
  beforeDestroy() {
    if (sessionStorage.getItem("pathToLoadUnAuthInApi") !== null) {
      sessionStorage.removeItem("pathToLoadUnAuthInApi");
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.conetext {
  &.login {
  }
}
</style>
